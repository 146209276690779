<template>
    <div class="friendContent">
        <!-- 友链 -->
            <!-- 友链列表 -->
            <div class="friend-nums">
                <div  @click="Jump(value.friendUrl)" class="friend-person" v-for="(value,index) in dataList" :key="index">
                    <div class="friend-head" >
                        <img :src="value.friendPhoto" alt="">
                    </div>
                    <div class="friend-brief">
                        <div class="friend-name"><span>{{value.friendName}}</span></div>
                        <div class="friend-jianjie"><span>{{value.friendDesc}}</span></div>
                    </div>
                </div>

                <div class="friend-app">
                    <p class="friend-title">🌹我的链接</p>
                    <br>
                    <p>链接名称:&nbsp;<span>Radish</span></p>
                    <p>链接地址:&nbsp;<span>fuqz.top</span></p>
                    <p>头像地址:&nbsp;<span>https://gitee.com/FQZS/photos/raw/master/img/blog/head.jpg</span></p>
                    <br>
                    <br>
                    <p class="friend-title">🌹申请友链的条件</p>
                    <br>
                    <ul>
                        <li> <p>既然来了，就加个友链吧(来者不拒)</p></li>
                        <li> <p>先友后链，申请前请先提前做好本站友情链接（🍧添加之后会邮件通知阁下的呢~）</p></li>
                        <li> <p>只想安静的和各位交个朋友 ，只要一有空会经常访问的~</p></li>
                        <li> <p>凡内容污秽、暴力的、广告挂马的、违背社会主义核心价值观的勿扰</p></li>
                    </ul>
                    <br>
                    <br>
                    <p class="friend-title">❌无法访问</p>
                    <br>
                    <p>一个月后仍无法访问，则删除链接，如果恢复了请在留言板给我留言🧐</p>
                </div>
             </div>
    </div>
</template>

<script>
    export default {
        name: "friendContent",
        data(){
            return {
                dataList:[]
            }
        },
        methods:{
            async friendList(){
                const res = await this.$home.friendList()
                this.dataList = res.data
            },
            /* 跳转新页面 */
            Jump(value){
                console.log("sss"+value)
                window.open(value)
            }
        },
        created() {
            this.friendList()
            this.$store.commit("updateRotation",false)
            /* 隐藏左侧 */
            this.$store.commit("updateLeftShow",true)
        }
    }
</script>

<style scoped>

</style>
